@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap");

* {
  box-sizing: border-box;
}

html {
  line-height: 1.5;
  font-family: Helvetica, Roboto, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica",
    sans-serif;
  font-weight: 600;

  scroll-behavior: smooth;
}

body {
  color: #0c2074;
  background-color: white;
  font-family: "Helvetica";
  font-weight: 200;
}

button {
  font-family: "Helvetica";
  font-weight: 400;
}

a {
  color: inherit;
  font-family: "Helvetica";
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  line-height: 1.25;
  font-family: "Helvetica";
  font-weight: 500;
}

@font-face {
  font-family: "HelveticaNeue";
  src: local("HelveticaNeue"),
    url("Fonts/HelveticaNeue/HelveticaNeue.eot") format("eot"),
    url("Fonts/HelveticaNeue/HelveticaNeue.ttf") format("ttf"),
    url("Fonts/HelveticaNeue/HelveticaNeue.woff") format("woff");
  /* font-weight: 600; */
}

@font-face {
  font-family: "HelveticaNeueBd";
  src: local("HelveticaNeueBd"),
    url("Fonts/HelveticaNeueBd/HelveticaNeueBd.eot") format("eot"),
    url("Fonts/HelveticaNeueBd/HelveticaNeueBd.ttf") format("ttf"),
    url("Fonts/HelveticaNeueBd/HelveticaNeueBd.woff") format("woff");
  /* font-weight: 400; */
}

@font-face {
  font-family: "HelveticaNeueMed";
  src: local("HelveticaNeueMed"),
    url("Fonts/HelveticaNeueMed/HelveticaNeueMed.eot") format("eot"),
    url("Fonts/HelveticaNeueMed/HelveticaNeueMed.ttf") format("ttf"),
    url("Fonts/HelveticaNeueMed/HelveticaNeueMed.woff") format("woff");
}

@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"),
    url("Fonts/Helvetica/Helvetica.eot") format("eot"),
    url("Fonts/Helvetica/Helvetica.ttf") format("ttf"),
    url("Fonts/Helvetica/Helvetica.woff") format("woff");
}

@font-face {
  font-family: MaxEmoji;
  src: url("Fonts/Emoji/Brand-Live-Emoji.otf") format("opentype");
}

.uppercase {
  text-transform: uppercase;
}
