@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap);
* {
  box-sizing: border-box;
}

html {
  line-height: 1.5;
  font-family: Helvetica, Roboto, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica",
    sans-serif;
  font-weight: 600;

  scroll-behavior: smooth;
}

body {
  color: #0c2074;
  background-color: white;
  font-family: "Helvetica";
  font-weight: 200;
}

button {
  font-family: "Helvetica";
  font-weight: 400;
}

a {
  color: inherit;
  font-family: "Helvetica";
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  line-height: 1.25;
  font-family: "Helvetica";
  font-weight: 500;
}

@font-face {
  font-family: "HelveticaNeue";
  src: local("HelveticaNeue"),
    url(../../static/media/HelveticaNeue.08086ffa.eot) format("eot"),
    url(../../static/media/HelveticaNeue.50a6cd50.ttf) format("ttf"),
    url(../../static/media/HelveticaNeue.b027d0bc.woff) format("woff");
  /* font-weight: 600; */
}

@font-face {
  font-family: "HelveticaNeueBd";
  src: local("HelveticaNeueBd"),
    url(../../static/media/HelveticaNeueBd.b159ddfa.eot) format("eot"),
    url(../../static/media/HelveticaNeueBd.497b4484.ttf) format("ttf"),
    url(../../static/media/HelveticaNeueBd.19388597.woff) format("woff");
  /* font-weight: 400; */
}

@font-face {
  font-family: "HelveticaNeueMed";
  src: local("HelveticaNeueMed"),
    url(../../static/media/HelveticaNeueMed.0cd71990.eot) format("eot"),
    url(../../static/media/HelveticaNeueMed.49f40b03.ttf) format("ttf"),
    url(../../static/media/HelveticaNeueMed.9fc3ab39.woff) format("woff");
}

@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"),
    url(../../static/media/Helvetica.1e6c831e.eot) format("eot"),
    url(../../static/media/Helvetica.06113bf7.ttf) format("ttf"),
    url(../../static/media/Helvetica.f530f4f1.woff) format("woff");
}

@font-face {
  font-family: MaxEmoji;
  src: url(../../static/media/Brand-Live-Emoji.757eaf58.otf) format("opentype");
}

.uppercase {
  text-transform: uppercase;
}

